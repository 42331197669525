<template>
  <div class="card card-custom gutter-b disabled-css" id="feedback-show">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <a href="javascript:" @click="$router.go(-1) " class="mr-3 align-items-center">
          <i class="fas fa-arrow-circle-left"></i>
        </a>
        <h1 class="card-label ml-3">Chi tiết ticket</h1>
      </div>
      <div class="card-title">
        <el-button type="success" class="btn btn-success"
                   :title="isNaN(ticketObj.feedback_ticket_star) ? 'Ticket chưa có đánh giá' : 'Xem đánh giá'"
                   :disabled="isNaN(ticketObj.feedback_ticket_star)" @click="dialogComment = true">
          Xem đánh giá
        </el-button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="container-content">
            <div class="card-title">
              <h4 class="card-label">Thông tin ticket</h4>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-6 form-group">
                <label>Mã ticket:</label>
                <el-input type="text" :value="ticketObj.id" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc != 13 && ticketObj.category_chamsoc != 14 && ticketObj.category_chamsoc != 16">
                <label>Phân loại:</label>
                <el-input type="text" :value="ticketObj.is_auto == 1 ? 'Chăm sóc học viên' : 'Yêu cầu hỗ trợ'" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 9">
                <label>Số điện thoại phụ huynh:</label>
                <el-input v-if="ticketObj.customer.name_use_app && ticketObj.customer.phone_use_app" type="text" :value="ticketObj.customer.name_use_app" disabled/>
                <el-input v-else type="text" :value="ticketObj?.contract?.user_parent?.parent?.phone" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 9">
                <label>Họ tên phụ huynh:</label>
                <el-input v-if="ticketObj.customer.name_use_app && ticketObj.customer.phone_use_app" type="text" :value="ticketObj.customer.phone_use_app" disabled/>
                <el-input v-else type="text" :value="ticketObj?.contract?.user_parent?.parent?.phone" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc != null && ticketObj.category_chamsoc != 10 && ticketObj.category_chamsoc != 11 ">
                <label>Lớp học:</label>
                <el-input type="text" :value="ticketObj?.classes?.name" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 1 || ticketObj.category_chamsoc == 7">
                <label>Các buổi đã nghỉ:</label>
                <el-input type="text" :value="ticketObj.lesson_leave" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc != 15 && ticketObj.category_chamsoc != 13 && ticketObj.category_chamsoc != 14 && ticketObj.category_chamsoc != 16">
                <label>Bên gửi phản hồi:</label>
                <el-input type="text" :value="transer == 1 ? 'TTHT' : (transer == 2 ? 'QLHV' : ticketObj.category_chamsoc == 6 || ticketObj.category_chamsoc == 7 || ticketObj.category_chamsoc == 9 ? 'QLHV' : 'TTHT')" disabled/>
              </div>
              <div class="col-md-6 form-group">
                <label>Danh mục:</label>
                <el-input type="text" :value="ticketObj.category_ticket ? ticketObj.category_ticket.name : category_chamsoc_define[ticketObj.category_chamsoc]" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 1 || ticketObj.category_chamsoc == 7">
                <label>Lý do {{show_reason[ticketObj.category_chamsoc]}} :</label>
                <el-select v-model="ticketObj.reason_id"
                           placeholder=""
                           disabled
                           clearable
                           collapse-tags
                           class="w-100"
                >
                  <el-option
                      v-for="item in listReason"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="form-group col-md-6" v-if="ticketObj.category_chamsoc == 8 || ticketObj.category_chamsoc == 13">
                <label>Lý do :</label>
                <el-select v-model="ticketObj.reason_id"
                           placeholder=""
                           disabled
                           clearable
                           collapse-tags
                           class="w-100"
                >
                  <el-option
                      v-for="item in listReasonAbsent"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc != 13 && ticketObj.category_chamsoc != 14 && ticketObj.category_chamsoc != 16 && ticketObj.category_chamsoc != 9 && ticketObj.category_chamsoc != 6 && ticketObj.category_chamsoc != null
                        && ticketObj.category_chamsoc != 10 && ticketObj.category_chamsoc != 11">
                <label>Phân loại nghỉ:</label>
                <input type="text" class="form-control form-control-solid"
                       :value="changeTypeOff(ticketObj.category_chamsoc, ticketObj.val)"
                       placeholder="Thời gian tạo ticket" disabled="">
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 16">
                <label>Phân loại:</label>
                <el-select
                  v-model="ticketObj.type_auto"
                  placeholder=""
                  clearable
                  disabled
                  collapse-tags
                  class="w-100"
                >
                  <el-option :value="1" label="Có khiếu nại">Có khiếu nại</el-option>
                  <el-option :value="2" label="Không có khiếu nại">Không có khiếu nại</el-option>
                </el-select>
              </div>
              <div class="col-md-6 form-group">
                <label>Thời gian tạo ticket:</label>
                <el-input type="text" :value="ticketObj.created_at | formatDateTimeAsia" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc != null ">
                <label>Chi nhánh :</label>
                <input type="text" class="form-control form-control-solid"
                       placeholder="Các buổi đã nghỉ" :value="ticketObj?.branch?.name" disabled>
              </div>
              <div class="col-md-6 form-group" v-if="show">
                <label>Vấn đề :</label>
                <el-select v-model="ticketObj.reason_id"
                           placeholder=""
                           disabled
                           clearable
                           collapse-tags
                           class="w-100"
                           placeholder="Phân loại vấn đề"
                >
                  <el-option
                    v-for="(item, index) in problems"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 14">
                <label>Tổng số buổi nghỉ :</label>
                <input type="text" class="form-control form-control-solid"
                       placeholder="Các buổi đã nghỉ" :value="ticketObj.count_leave_ticket" disabled>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 14">
                <label>Lý do nghỉ:</label>
                <el-select v-model="ticketObj.reason_id"
                           placeholder="Lý do nghỉ"
                           disabled
                           clearable
                           collapse-tags
                           class="w-100"
                >
                  <el-option
                    v-for="(item, index) in listReasonOffLesson"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>

              <div class="col-md-6 form-group" v-if="show">
                <label>Chi tiết vấn đề :</label>
                <textarea class="form-control" id="exampleTextarea" rows="3"
                          v-model="ticketObj.reason_absence" disabled></textarea>
              </div>
              <div class="col-md-6 form-group">
                <label>Bên xử lý:</label>
                <el-input type="text" :value="ticketObj.phong_ban_xu_ly_rel && ticketObj.phong_ban_xu_ly_rel.name" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc != 15 && ticketObj.category_chamsoc != 13 && ticketObj.category_chamsoc != 14 && ticketObj.category_chamsoc != 16">
                <label>Độ ưu tiên</label>
                <el-input type="text" :value="priority_define[ticketObj.priority]" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 10 || ticketObj.category_chamsoc == 11">
                <label>Tình trạng BTVN:</label>
                <el-input type="text" :value="ticketObj.category_chamsoc == 10 ? 'Kết quả thấp' : 'Không làm bài tập về nhà' " disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 10 || ticketObj.category_chamsoc == 11">
                <label>Buổi học:</label>
                <el-input type="text" :value="ticketObj.lesson_leave ? ticketObj.lesson_leave : ''" disabled/>
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 10 || ticketObj.category_chamsoc == 11 ">
                <label>lớp học:</label>
                <el-input type="text" :value="ticketObj.classes ? ticketObj.classes.name : ''" disabled/>
              </div>
              <div v-if="ticketObj.category_chamsoc == 6" class="col-md-6 form-group">
                <label>Số sao:</label>
                <input type="text" class="form-control form-control-solid" disabled=""
                       :value="ticketObj.feedback_star" placeholder="Số sao">
              </div>
              <div class="col-md-6 form-group" v-if="ticketObj.category_chamsoc == 6">
                <label>Lý do feedback:</label>
                <input type="text" class="form-control form-control-solid" disabled=""
                       :value="ticketObj.feedback_ticket_comment" placeholder="Lý do feedback">
              </div>
              <div class="col-md-12 form-group" v-if="ticketObj.category_chamsoc == 6">
                <div class="form-group mb-1">
                  <label for="exampleTextarea">Chi tiết feedback
                    <span class="text-danger">*</span></label>
                  <textarea class="form-control" id="exampleTextarea" rows="3"
                            v-model="ticketObj.feedback_comment"></textarea>
                </div>
              </div>
              <div class="col-12 form-group"  v-if="ticketObj.category_chamsoc != 16 && ticketObj.category_chamsoc != 15 && ticketObj.category_chamsoc != 14 && ticketObj.category_chamsoc != 10 && ticketObj.category_chamsoc != 11 && ticketObj.category_chamsoc != 13">
                <label>Nội dung :</label>
                <el-input class="w-100"
                          :rows="5"
                          :value="ticketObj.content"
                          maxlength="1000"
                          show-word-limit
                          disabled
                          type="textarea">

                </el-input>
              </div>
              <div class="col-12 form-group"  v-if="ticketObj.category_chamsoc == 15">
                <label>Chi tiết lí do :</label>
                <el-input class="w-100"
                          :rows="5"
                          :value="ticketObj.reason_absence"
                          maxlength="1000"
                          show-word-limit
                          disabled
                          type="textarea">

                </el-input>
              </div>
              <div class="col-md-12 pl-0 form-group" v-else>
                  <div v-if="ticketObj.category_chamsoc == 10 || ticketObj.category_chamsoc == 11">
                      <button :disabled="viewHomework" v-if="ticketObj.category_chamsoc == 10" class="btn btn-sm btn-outline-info m-4" @click="viewHomeworkBadScore">Xem chi tiết BTVN</button>
                      <button :disabled="viewHomework" v-if="ticketObj.category_chamsoc == 11" class="btn btn-sm btn-outline-info m-4" @click="viewHomeworkNotWorks">Xem chi tiết BTVN</button>
                  </div>
              </div>
            </div>
            <!-- Ảnh media -->
            <div class="row">
              <div class="form-group col-12" v-if="((tickets_images.length > 0 || tickets_videos.length > 0) || (ticketObj.category_chamsoc != 10 || ticketObj.category_chamsoc != 11)) && ticketObj.category_chamsoc != 15 && ticketObj.category_chamsoc != 13 && ticketObj.category_chamsoc != 14 && ticketObj.category_chamsoc != 16">
                <label>Ảnh/video:</label>
                <div class="ticket-media d-flex">
                <UploadVideo
                        :disableUpload="false"
                        :fileListProp="ticketObj.attach_video_image_files"
                        :isDisable="true"
                        :mediaIds="ticketObj.media_ids"
                        @emitUpdateFile="emitUpdateFile"
                        @spliceMediaId="spliceMediaId">
                </UploadVideo>
                </div>
                </div>
            </div>
            <hr>
            <div class="card-title">
              <h4 class="card-label">Thông tin học viên</h4>
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <label>Họ tên:</label>
                <div class="mt-3">
                  <router-link :to="{ name: 'customer-crm-detail', params: {id: ticketObj.student_edutalk &&  ticketObj.student_edutalk.id },
                  query: { best_customer_id: ticketObj.student_edutalk && ticketObj.student_edutalk.best_customer
                  && ticketObj.student_edutalk.best_customer.id, active_name: 'cskh' } }"
                               class="badge badge-success">{{ ticketObj.student_edutalk && ticketObj.student_edutalk.name }}</router-link>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label>Số điện thoại liên hệ:</label>
                <el-input type="text" :value="getPhone" disabled />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="container-content">
            <div class="d-flex justify-content-between">
              <div class="card-title">
                <h4 class="card-label">Trạng thái chăm sóc</h4>
              </div>
              <div class="title">
                <p class="ticket-log-title">Trạng thái :</p>
                <p v-if="ticketObj.status == 1" class="badge badge-warning">{{ STATUS[ticketObj.status] }}</p>
                <p v-if="ticketObj.status == 2" class="badge badge-primary">{{ STATUS[ticketObj.status] }}</p>
                <p v-if="ticketObj.status == 3" class="badge badge-success">{{ STATUS[ticketObj.status] }}</p>
              </div>

            </div>
              <div class="col-12" v-if="!boxCommentReason">
                  <button :disabled="ticketObj.status == 3" @click="boxCommentReason = true" style="width: 100%; height: 51px; text-align: start; color: rgba(0, 0, 0, 0.5);" class="border rounded border-1 pl-4">Nhập giải pháp hoặc bình luận</button>
              </div>
              <div class="form-group mb-1 col-md-12" v-if="boxCommentReason">
                <ValidationProvider vid="content" name="Nội dung" rules="required"
                                    v-slot="{ errors, classes }">
                    <div>
                    <i title="Đóng" @click="boxCommentReason = false" style="position: absolute; right: 18px; top: 5px" class="el-icon-close d-flex justify-content-end closes-edit-feedback-show-log"></i>
                  </div>
                  <ckeditor style="position: relative;" class="ckeditor-feedback" :editor="editor" v-model="query.reason"></ckeditor>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
                <div class="box-bottom-comment-reason d-flex justify-content-end">
                  <div class="d-flex align-items-end">
                    <el-checkbox v-if="!(ticketObj.status == 3)" v-model="query.is_solution">Đánh dấu là giải pháp</el-checkbox>
                  </div>
                  <div class="">
                    <b-button v-if="!(ticketObj.status == 3)"  variant="success" size="md" class="float-right ml-2" :disabled="loadingSave" :class="{'spinner spinner-white spinner-right' : loadingSave}" @click="handlerCommentReasonTicket">Lưu</b-button>
                  </div>
                </div>
            </div>
              <div class="col-md-12 mt-8 show-comment">
                <div class="" style="scroll-behavior: smooth;">
                  <template class="w-100">
                  <el-tabs  v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="Tất cả" name="all">
                    </el-tab-pane>
                    <el-tab-pane label="Giải pháp" name="solution">
                    </el-tab-pane>
                    <el-tab-pane label="Bình luận" name="comment">
                    </el-tab-pane>
                  </el-tabs>
                  <AllTicketLog
                      v-loading="loadingTabName"
                      :tickets="ticketLog"
                      :tabName="activeName">
                  </AllTicketLog>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal :centered="true" :scrollable="true" content-class="your-class" size="lg" body-class="modal-video"  hide-footer hide-header ref="modal-video-ticket">
        <video controls id="video1" style="width: 100%; height: 98%" ref="video">
          <source :src="targetVideo" type="video/mp4">
          Your browser doesn't support HTML5 video tag.
        </video>
      </b-modal>
    </div>
    <el-dialog title="Đánh giá của người phản hồi" center :visible.sync="dialogComment">
      <comment :ticket="ticketObj"></comment>
      <div slot="footer" class="dialog-footer text-right">
        <el-button @click="dialogComment = false" type="info" class="btn btn-info">Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import RateTicket from "@/view/pages/feedback/RateTicket";
import Comment from "@/view/pages/feedback/Comment";
import {CATEGORY, CATEGORY_CHAMSOC, IMAGE_OPTION, STATUS, VIDEO_OPTION, DOI_TUONG_PHAN_HOI} from "@/core/option/ticketOption";
import '@/assets/sass/ticket/ticketDetail.scss'
import {SHOW_TICKET, UPDATE_TICKET_FEEDBACK, SHOW_TICKET_LOG} from "@/core/services/store/ticket/ticket.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import AllTicketLog from "../../pages/crm/tickets/components/AllTicketLog.vue";
import {GET_TICKET_REASON_BY_TYPE, GET_LEARNING_SCHEDULE_ID} from "@/core/services/store/user/ticket.module";
import UploadVideo from '../tickets/components/UploadVideo.vue';

export default {
  name: "FeedbackShow",
  components: { RateTicket, AllTicketLog, UploadVideo},
  data() {
    return {
      type: '',
      show: false,
      allDataComment: [],
      ticketObj: {},
      ticketLog: [],
      editor: CustomEditor,
      ticket_id: '',
      fileList: [],
      learning_schedule_id: '',
      CATEGORY,
      CATEGORY_CHAMSOC,
      STATUS,
      VIDEO_OPTION,
      IMAGE_OPTION,
      status_ticket: '',
      DOI_TUONG_PHAN_HOI,
      media: [],
      targetVideo: '',
      tickets_images: [],
      tickets_videos: [],
      dialogRate: false,
      dialogComment: false,
      dialogRefuseSolution: false,
      loadingTabNameL: false,
      listReason: [],
      activeName: 'all',
      boxCommentReason: false,
      query: {
              ticket_id: '',
              type: 'change_status',
              user_handle: '',
              old_content: '',
              new_content: '',
              content: '',
              reason: '',
              reason_refuse: '',
              is_auto: 1,
              is_solution: false
            },
      category_chamsoc_define: {
        1: 'Nghỉ học 2 buổi',
        2: 'Điểm kiểm tra thấp',
        3: 'Không làm bài',
        4: 'Chua đóng tiền học',
        5: 'Đã đóng tiền học',
        6: 'Feedback thấp',
        7: 'Nghỉ học 1 buổi',
        8: 'Vắng có phép',
        9: 'Hướng dẫn app cho phụ huynh',
        10: 'Kết quả BTVN không tốt',
        11: 'Kết quả BTVN không tốt',
        13: 'Nhắc nhở bảo hành',
        14: 'Nghỉ 1 buổi không phép',
        15: 'Nghỉ buổi khai giảng',
        16: 'Chăm sóc học viên giữa khóa'
      },
      priority_define: {
        1: 'Tiêu chuẩn',
        2: 'Ưu tiên',
      },
      show_reason: {
        1: 'nghỉ',
        6: 'feedback',
        7: 'nghỉ'
      },
      listReasonAbsent: [
        {
          'id': 1,
          'value': 'Nghỉ hẳn lớp học',
        },
        {
          'id': 2,
          'value': 'Bận việc cá nhân',
        },
        {
          'id': 3,
          'value': 'Vấn đề ve sức khỏe',
        },
        {
          'id': 4,
          'value': 'Quân sự/ thực tập',
        },
        {
          'id': 5,
          'value': 'Khác',
        },
        {
          id: 25,
          value:  '2 buổi không làm BTVN',
        },
        {
          id: 26,
          value:  'Nghỉ học 2 buổi',
        },
        {
          id: 27,
          value:  'Nghỉ 1 buổi + Không làm BTVN 1 buổi.',
        },
      ],
      transer: '',
      loadingReffuseSolution: false,
      loadingSave:  false,
      problems: [
        {
          id: 1,
          name:  'Feedback Giáo viên',
        },
        {
          id: 19,
          name:  'Feedback trợ giảng',
        },
        {
          id: 3,
          name:  'Cơ sở vật chất',
        },
        {
          id: 20,
          name:  'Xin thêm tài liệu',
        },        {
          id: 21,
          name:  'Feedback App BTVN',
        },
        {
          id: 22,
          name:  'Yêu cầu bổ trợ',
        },
        {
          id: 23,
          name:  'Khác',
        },
      ],
      listReasonOffLesson: [
        {
          id: 9,
          name:  'Bận việc cá nhân',
        },
        {
          id: 10,
          name:  'Trùng lịch học',
        },
        {
          id: 11,
          name:  'Nghỉ ốm',
        },
        {
          id: 12,
          name:  'Du lịch',
        },        {
          id: 13,
          name:  'Quân sự/ Thực tập',
        },
        {
          id: 14,
          name:  'Bảo lưu',
        },
        {
          id: 24,
          name:  'Nghỉ hẳn',
        },
        {
          id: 23,
          name:  'Khác',
        },
      ],
    }
  },
  created() {
      this.showTicket();
      this.showTicketLog();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách phản hồi", route: 'feedback-list'},
      {title: "Chi tiết ticket"}
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
    getPhone(){
      if (!this.ticketObj.student_edutalk) {
        return  '';
      }

      if (this.ticketObj.student_edutalk.phone_other){
        return this.ticketObj.student_edutalk.phone_other;
      }

      if (this.ticketObj.student_edutalk.phone){
        return this.ticketObj.student_edutalk.phone;
      }

      return  '';
    }
  },
  methods: {
    showTicket() {
      this.$store.dispatch(SHOW_TICKET, this.$route.params.id).then((res) => {
        this.ticketObj = res.data;
        this.status_ticket = res.data.status;
        this.transer = res.data.transer_ticket_log.length > 0 ? res.data.transer_ticket_log[0].assignor_id : '';
        this.ticket_id = res.data.id;
        this.tickets_images = [];
        this.tickets_videos = [];
        if(this.ticketObj.category_chamsoc == 10 ||this.ticketObj.category_chamsoc == 11) {
             this.getScheduleId();
        }
        if (this.ticketObj.type_auto == 1)
        {
          this.show = true;
        }
        this.ticketObj.attach_files.forEach((item) => {
          this.IMAGE_OPTION.forEach((value) => {
            if (item.includes(value)) {
              this.tickets_images.push(item);
            }
          });
        })
        this.ticketObj.attach_files.forEach((item) => {
          this.VIDEO_OPTION.forEach((value) => {
            if (item.includes(value)) {
              this.tickets_videos.push(item);
            }
          });
        })
        if (this.ticketObj.reason_id === 0) {
          this.ticketObj.reason_id = '';
        }
        this.getReason();
        this.getScheduleId();
        this.showTicketLog();
      });
    },

    showTicketLog() {
      this.loadingTabName = true;
      this.$store.dispatch(SHOW_TICKET_LOG, {
          id: this.$route.params.id,
          type: this.activeName
        }).then((data) => {
          this.loadingTabName = false;
          this.ticketLog = data.data;
        })
    },

    handleClick() {
      this.showTicketLog();
    },

    showImageIdentity(element) {
      this.$refs["img-detail"].src = element.srcElement.src;
      this.$refs["modal-show-img"].style.display = "flex";
    },
    showModalVideo(video) {
      let _this = this;
      this.targetVideo = video;
      this.$refs['modal-video-ticket'].show();
      setTimeout(function () {
        let modalContent = document.querySelector('.your-class');
        modalContent.style.background = 'none';
        modalContent.style.boxShadow = 'none';
        _this.$refs['video'].play();
      }, 200);
    },
    renderStatusLog(log) {
      if (log.type === 'change_status'){
        return `Chuyển trạng thái từ
                <span class="font-weight-bold">${this.STATUS[log.old_content]}</span> sang
                <span class="font-weight-bold">${this.STATUS[log.new_content]}</span>`
      }
      return '';
    },
    renderBenGuiPhanHoi(is_auto){
      let result = '';
      switch (is_auto){
        case 2:
          result = 'TTHT';
          break;
        case 3:
          result = 'QLHV';
          break;
      }
      return result;
    },
    renderDoiTuongPhanHoi(doi_tuong_phan_hoi){
      let result = this.DOI_TUONG_PHAN_HOI.find( item => item.value === Number(doi_tuong_phan_hoi));
      return result ? result.label : '';
    },
    mapImages(log) {
      const images = [];
      log.attach_files.forEach((item) => {
        this.IMAGE_OPTION.forEach((value) => {
          if (item.includes(value)) {
            images.push(item);
          }
        });
      })
      return images;
    },
    mapVideos(log) {
      const videos = [];
      log.attach_files.forEach((item) => {
        this.VIDEO_OPTION.forEach((value) => {
          if (item.includes(value)){
            videos.push(item);
          }
        });
      });
      return videos;
    },
    successUpdate(ticket) {
      this.dialogRate = false;
      this.showTicket();
    },
    async getReason() {
      await this.$store.dispatch(GET_TICKET_REASON_BY_TYPE, this.ticketObj.category_chamsoc).then((res) => {
        if (!res.error) {
          this.listReason = res.data
        }
      });
    },

    getScheduleId() {
        this.$store.dispatch(GET_LEARNING_SCHEDULE_ID, {
          ticket_id: this.ticket_id
        }).then((data) => {
          this.learning_schedule_id = data.data;
          if(data.error) {
            this.viewHomework = true;
          }
        })
    },

    viewHomeworkBadScore(){
      const routeData = this.$router.resolve({ name: "content-homework",params: {id : this.ticketObj.classroom_id},
      query: { classroom_id: this.ticketObj.classroom_id,learning_schedule_id:this.learning_schedule_id,student_id :
               this.ticketObj.user_id, lesson: this.ticketObj.lesson_leave , type:'view-result'}});
               window.open(routeData.href, '_blank');
    },

    viewHomeworkNotWorks(){
      const routeData = this.$router.resolve({ name: "content-homework",params: {id : this.ticketObj.lesson_leave},
      query: { classroom_id: this.ticketObj.classroom_id, learning_schedule_id:this.learning_schedule_id}});
      window.open(routeData.href, '_blank');
    },

    handlerCommentReasonTicket() {
      let reason = this.$el.querySelector(".ck-content").innerHTML;
      if(!this.query.reason) {
        this.$notify.warning({
            title: 'Thiếu dữ liệu',
            message: 'Vui lòng nhập đủ dữ liệu',
            offset: 100
          });
          return false;
      }
      this.loadingSave = true;
      var is_solution =  this.query.is_solution ? 1 : 0;
      var is_feedback_processing_deprtment = !is_solution ? 1 : 0;
      setTimeout(() => {
        this.$store.dispatch(UPDATE_TICKET_FEEDBACK,{
            ticket_id: this.$route.params.id,
            is_solution : is_solution,
            reason: reason,
            status: this.ticketObj.status,
            is_feedback_processing_deprtment,
            status_feedback: 1
            }).then((res) => {
              this.loadingSave = false;
              this.boxCommentReason = false;
              this.query.reason = '';
              this.showTicket();
              this.activeName = "all";
              this.showTicketLog();
                if(is_solution) {
                  this.query.is_solution = false;
                  this.$notify.success({
                  title: 'Thành công',
                  message: 'Nhập giải pháp thành công',
                  offset: 100
                });
                }else {
                  this.$notify.success({
                  title: 'Thành công',
                  message: 'Nhập bình luận thành công',
                  offset: 100
                });
                }
            }).catch((e) => {

            }).finally(() => {

          });
      }, 4000);
      clearTimeout();
    },
    emitUpdateFile(e) {
      // this.fileList = e;
    },
    spliceMediaId(data) {
      this.ticketObj.media_ids = data;
    },
    changeTypeOff(type, val)
    {
      let text = '';
      switch (type) {
        case '7':
          text = 'Không phép';
          break;
        case '16':
          text = 'Có khiếu nại';
          break;
        default:
          if (val == 2) {
            text = 'Có phép';
          } else if (val == 0) {
            text = 'Không phép';
          }
          break;
      }
      console.log(type, text)

      return text;
    },
  }
}
</script>

<style>
  #feedback-show .el-loading-spinner .circular {
    margin-top: 5px;
    height: 36px;
    width: 28px;
}
  .btn-refuse-solution {
      width: 95.75px;
      height: 38px;
      border-radius: 3px;
  }

  #RefuseSolution .el-dialog .el-input__inner{
    color: #606266;
    word-break: break-all;
    height: 100px;
    border-radius: 10px;
    position: relative;
  }

  #RefuseSolution input::placeholder {
    position: absolute;
    top: 20%;
  }

  #RefuseSolution .el-dialog__title {
   font-weight: 700;
  }
  #feedback-show .ck-content .image-inline img, .ck-content .image-inline picture,
  #feedback-show .ck-content .image img {
      height: 300px;
      width: 200px;
      object-fit: cover;
  }

  .closes-edit-feedback-show-log::before{
    border: 1px solid #00000059;
    border-radius: 50px;
    font-size: 12px;
    padding: 1px;
    z-index: 1000;
  }

  .closes-edit-feedback-show-log:hover{
   color: red;
  }
</style>
